/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull unified-home'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 29
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/implementation/unified-home)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: '6cfe80b109d58db414efa2dbae828525'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '29',
    branch: 'main',
    source: 'unified-home',
    versionId: 'c4a680f9-de04-4c3c-8ddc-e483e6ae6cf5'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  Account?: any;
  /**
   * A list of the survey IDs a user is the admin of, if any.
   */
  "Admin of surveys"?: any;
  "App Context"?: any;
  /**
   * The user's association type to the business (e.g. are they an employee or a consultant)
   */
  "Association type"?: string;
  /**
   * The date that the user account was created in the platform.
   */
  "Creation date"?: any;
  /**
   * January 2023 - Users will see a check box to tick off items that have been discussed in the 1:1. The hypothesis is the check box will increase usage of the agenda feature. The check box will be on the left with the drag handle on the left or right depending on the variation.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DEFAULT, REORDER_IN_RIGHT, REORDER_IN_LEFT |
   */
  "Experiment variations: 1 on 1 agenda check box and reorder"?: "DEFAULT" | "REORDER_IN_RIGHT" | "REORDER_IN_LEFT";
  /**
   * This experiment was run in May 2023, to test whether the removal of an informative modal step when syncing a calendar event to a conversation with a direct report, will increase conversion rates. Users saw each variation on the 'Set Up 1 on 1 Calendar Integration Modal Viewed' page.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DEFAULT, REMOVE_INFO_MODAL |
   */
  "Experiment variations: 1 on 1 calendar information modal"?: "DEFAULT" | "REMOVE_INFO_MODAL";
  /**
   * This experiment was run in June 2022, testing whether users are more likely to interact with agendas more by presenting users with different variations of the 1 on 1 check-in, aiming to improve week 1 user retention. 2 different variations will present the user with the sliders at the top of the checkin, one collapsible, the other not. This flag will only be sent to users who visit the 1 on 1 check-in space.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DEFAULT, CHECK_IN_AT_TOP, COLLAPSIBLE_CHECK_IN_AT_TOP |
   */
  "Experiment variations: 1 on 1 reordered check-in"?: "DEFAULT" | "CHECK_IN_AT_TOP" | "COLLAPSIBLE_CHECK_IN_AT_TOP";
  /**
   * November 2023 - testing whether the first version of Home Connect released is more likely to drive greater homepage conversion of continuous performance actions. When this flag is false, users will see the old/existing version. When it's true, they will see the new version.
   */
  "Experiment variations: home connect v1"?: boolean;
  /**
   * June 2022 - testing whether rotating tiles on the home screen faster will make admins more likely to interact with them. When this flag is false, the survey closed/survey summary tiles will rotate every few weeks. When it’s true, the tiles will rotate every week.
   */
  "Experiment variations: home screen survey tile rotation"?: boolean;
  /**
   * August 2022 - testing whether a placing the task list below the home tiles will increase interactions and completions of tasks. When this value is true, the task list will be below the home tiles. When false, users will receive the default experience
   */
  "Experiment variations: home screen task list reposition"?: boolean;
  /**
   * July 2022 - testing whether a complete redesign of the survey welcome page will have any effect on whether users proceed through the survey. When this flag is false, users will see the old/existing version. When it's true, they will see the new version.
   */
  "Experiment variations: survey welcome page redesign"?: boolean;
  /**
   * A Flow-only user is a user in Slack or Teams who does not match to a Culture Amp user, thus has no userAggregateID. The ID for a Flow-only user should have 'flow' appended, e.g. flow-123456. This field will be empty on most users.
   */
  "Flow-only user"?: boolean;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_li_fat_id?: string;
  initial_msclkid?: string;
  /**
   * The referral source for the user to the product, the first time we ever see that user.
   */
  initial_referrer?: string;
  /**
   * The referral domain for the user to the product, the first time we ever see that user.
   */
  initial_referring_domain?: any;
  /**
   * The first referring domain for this user
   */
  "initial_referring_domain\t"?: string;
  initial_rtd_cid?: string;
  initial_ttclid?: string;
  initial_twclid?: string;
  /**
   * The UTM campaign source for the user to the product, the first time we ever see that user.
   */
  initial_utm_campaign?: any;
  /**
   * The UTM content value received, the first time we ever see that user. This is typically the name of the communication someone was sent.
   */
  initial_utm_content?: any;
  initial_utm_id?: string;
  /**
   * The UTM medium value received, the first time we ever see that user.
   */
  initial_utm_medium?: any;
  /**
   * The UTM source value received, the first time we ever see that user.
   */
  initial_utm_source?: any;
  /**
   * The UTM term value received, the first time we ever see that user.
   */
  initial_utm_term?: any;
  initial_wbraid?: string;
  /**
   * The first referral domain for this user to the product
   */
  intial_referring_domain?: string;
  /**
   * DEPRECATED - INCORRECT DATA; Is the user an account level admin?
   */
  "Is account admin"?: any;
  /**
   * Is the user an account level admin?
   */
  "Is account admin v2"?: string;
  /**
   * Is the user a manager? 'Unknown' indicates that we have no data and 'No' indicates we have data that tells us that they are not.
   */
  "Is manager"?: any;
  /**
   * Locale (language) chosen by the user. Users that have not chosen a locale use the account default. Originates from Influx.
   */
  Locale?: string;
  "Mongo user ID"?: any;
  /**
   * The referrer for this session
   */
  referrer?: string;
  /**
   * The referring domain for this session
   */
  referring_domain?: string;
  "Sign-in count"?: any;
  /**
   * The communication platform a user has set up for skills coach
   */
  "Skills coach communication platform"?: any;
  /**
   * (Superceded by the derived property of the same name). The age of the User Account in days from the date the account was created.
   */
  "User account age in days"?: any;
  /**
   * (Superceded by the derived property of the same name). The age of the User Account in months from the date the account was created.
   */
  "User account age in months"?: any;
  /**
   * (Superceded by the derived property of the same name). The age of the User Account in weeks from the date the account was created.
   */
  "User account age in weeks"?: any;
  /**
   * The User's age in years, calculated from their date of birth (if known).
   */
  "User age"?: any;
  /**
   * The users age range ie between 18-24 years old. Derived from Murmur date of birth (optional field). Added for grouping purposes. The prefixed letter is to assist ordering.
   */
  "User age range"?: any;
  /**
   * What user-level feature flags did the users have enabled?
   */
  "User feature flag enabled"?: any;
  /**
   * The UTM campaign source for the user for that particular session.
   */
  utm_campaign?: any;
  /**
   * The UTM content value for the session. This is typically the name of the communication someone was sent
   */
  utm_content?: any;
  /**
   * The UTM medium value for the user for that particular session.
   */
  utm_medium?: any;
  /**
   * Identifies where the user came from e.g. Slack, Email, Academy
   */
  utm_source?: any;
  /**
   * The UTM term value for the user, for that particular session.
   */
  utm_term?: any;
  /**
   * The height (in pixels) of the user's viewport. A viewport is essentially size of the content area in the browser.
   */
  "Viewport height"?: any;
  /**
   * The width (in pixels) of the user's viewport. A viewport is essentially size of the content area in the browser.
   */
  "Viewport width"?: any;
}

export interface HomepageAdminTilesViewedProperties {
  /**
   * The names of tiles displayed to the user
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Tiles names displayed": string[];
}

export interface HomepageFeedViewedProperties {
  /**
   * What sections of the homepage feed are displayed? (e.g. "Recent, Upcoming")
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Feed sections displayed"?: string[];
  "Tile names displayed"?: any;
  /**
   * The names of tiles displayed to the user
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Tiles names displayed": string[];
}

export interface HomepageTasksViewedProperties {
  /**
   * The number of overdue tasks the user sees on their task list
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of overdue tasks displayed": number;
  /**
   * The number of subtasks the user sees on their task list
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of subtasks displayed": number;
  /**
   * The number of tasks the user sees on their task list
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of tasks displayed": number;
  /**
   * A list of the task types displayed
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Task types displayed"?: string[];
}

export interface HomepageTileClickedProperties {
  /**
   * The location on the feed a tile is displayed e.g. "Recent"
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recent, Upcoming, Suggested, Empty Feed |
   */
  "Feed section"?: "Recent" | "Upcoming" | "Suggested" | "Empty Feed";
  /**
   * The ID of tile interacted with e.g. 'Find a colleague'
   */
  "Tile name": string;
  /**
   * The type of tile that are clicked on unified-home which helps us differentiate them
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | primary, secondary, discovery, modal confirmation, direct reports, your one on one, your organisation, feed |
   */
  "Tile type":
    | "primary"
    | "secondary"
    | "discovery"
    | "modal confirmation"
    | "direct reports"
    | "your one on one"
    | "your organisation"
    | "feed";
}

export interface HomepageTilesViewedProperties {
  /**
   * The number of tiles displayed to the user
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of tiles displayed": number;
  /**
   * The names of tiles displayed to the user
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Tiles names displayed": string[];
}

export interface OnboardingQuestionnaireCompletedProperties {
  /**
   * The help topic a new admin has chosen on the Welcome page.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Encouraging regular feedback, Improving employee engagement, Strengthening employee learning and development, Implementing performance review processes, Other, Reducing employee turnover, I'm not sure, Creating an equitable and inclusive culture |
   */
  "Help option":
    | "Encouraging regular feedback"
    | "Improving employee engagement"
    | "Strengthening employee learning and development"
    | "Implementing performance review processes"
    | "Other"
    | "Reducing employee turnover"
    | "I'm not sure"
    | "Creating an equitable and inclusive culture";
}

export interface UnifiedHomeTaskClickedProperties {
  /**
   * The name/title of the action the user has either selected (Unified home), requested feedback on (Engagement), or submitted feedback for (Engagement).
   */
  "Action title"?: string;
  /**
   * How many days until the task is due (negative for overdue)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Days until due"?: number;
  /**
   * The number of tasks the user sees on their task list
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Number of tasks displayed": number;
  /**
   * What position was the task displayed in (e.g. top = 1)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Task position": number;
  /**
   * The type of task interacted with e.g. 'Take Survey'
   */
  "Task title": string;
  /**
   * The title of task interacted with e.g. 'Take Survey'
   */
  "Task type"?: any;
}

export interface UnifiedHomeTileClickedProperties {
  /**
   * The ID of tile interacted with e.g. 'Find a colleague'
   */
  "Tile name": string;
  /**
   * The type of tile that are clicked on unified-home which helps us differentiate them
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | primary, secondary, discovery, modal confirmation, direct reports, your one on one, your organisation, feed |
   */
  "Tile type":
    | "primary"
    | "secondary"
    | "discovery"
    | "modal confirmation"
    | "direct reports"
    | "your one on one"
    | "your organisation"
    | "feed";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomepageAdminTilesViewed implements BaseEvent {
  event_type = 'Homepage Admin Tiles Viewed';

  constructor(
    public event_properties: HomepageAdminTilesViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomepageFeedViewed implements BaseEvent {
  event_type = 'Homepage Feed Viewed';

  constructor(
    public event_properties: HomepageFeedViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomepageTasksViewed implements BaseEvent {
  event_type = 'Homepage Tasks Viewed';

  constructor(
    public event_properties: HomepageTasksViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomepageTileClicked implements BaseEvent {
  event_type = 'Homepage Tile Clicked';

  constructor(
    public event_properties: HomepageTileClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomepageTilesViewed implements BaseEvent {
  event_type = 'Homepage Tiles Viewed';

  constructor(
    public event_properties: HomepageTilesViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingQuestionnaireCompleted implements BaseEvent {
  event_type = 'Onboarding Questionnaire Completed';

  constructor(
    public event_properties: OnboardingQuestionnaireCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingQuestionnaireStarted implements BaseEvent {
  event_type = 'Onboarding Questionnaire Started';
}

export class UnifiedHomeTaskClicked implements BaseEvent {
  event_type = 'Unified Home Task Clicked';

  constructor(
    public event_properties: UnifiedHomeTaskClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnifiedHomeTileClicked implements BaseEvent {
  event_type = 'Unified Home Tile Clicked';

  constructor(
    public event_properties: UnifiedHomeTileClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Homepage Admin Tiles Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Homepage%20Admin%20Tiles%20Viewed)
   *
   * User views the admin tiles on the homepage, displayed outside the feed.
   *
   * Triggers:
   * 1. User views the homepage and is displayed tiles relating to 'Your organization'. 
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/3_kAhl8P3579bh8o2kVd9N0bZN_EbHCNT7N3xJdVVIjkIDh8Zws8UaGWHrzQKJS5UeTj1K0hmZ3rCu68qYRfUUJGYBA7j2bwRf0=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/4LexPB0B83/trigger/Q9spb2yRM)  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * @param properties The event's properties (e.g. Tiles names displayed)
   * @param options Amplitude event options.
   */
  homepageAdminTilesViewed(
    properties: HomepageAdminTilesViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageAdminTilesViewed(properties), options);
  }

  /**
   * Homepage Feed Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Homepage%20Feed%20Viewed)
   *
   * User views the homepage feed where relevant tiles are displayed in a connected feed format.
   *
   * Triggers:
   * 1. User views the homepage where a feed is displayed. 
   *   _Triggered on unified-home_
   *   ![](https://lh3.googleusercontent.com/mRPqbe03Yv-R-2e5jkWx7kv17r4aBNN_jIxoxTJzIwa5Gfi8CD1VrG8V_uCwJOoJ6HF6GKHYc06OgSmvxXaUeb1MJlSE4JAPCnRS=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/aQ8vOxypGE/trigger/MCmgmLX55)
   * 2. User views the homepage with an empty feed. 
   *   _Triggered on unified-home_
   *   ![](https://lh3.googleusercontent.com/HqcauaYaXtJNhWwk-TcoXdkIQ-v11eseKxthxuJomp375cNfNd5aMgF3HMf6HDfb-9KVSMNscr_Dw0f-MELjfv-hSlvQEzBJ018=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/aQ8vOxypGE/trigger/uJ21gJRwE)  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * @param properties The event's properties (e.g. Feed sections displayed)
   * @param options Amplitude event options.
   */
  homepageFeedViewed(
    properties: HomepageFeedViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageFeedViewed(properties), options);
  }

  /**
   * Homepage Tasks Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Homepage%20Tasks%20Viewed)
   *
   * User views the tasks on the homepage, displayed outside the feed.
   *
   * Triggers:
   * 1. User views the task list on the homepage. 
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/88wDD7LRKHzP2nDbGKysFT_4DsTOxXU-EhbH3vdcs3jWlQjIEmJve0i5VTuABrA70uw4azlrCbD9yCIsOE0wD659HXKw23CJYfKT=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/gqwpZkIvca/trigger/hxafkoU4M)  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * @param properties The event's properties (e.g. Number of overdue tasks displayed)
   * @param options Amplitude event options.
   */
  homepageTasksViewed(
    properties: HomepageTasksViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageTasksViewed(properties), options);
  }

  /**
   * Homepage Tile Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Homepage%20Tile%20Clicked)
   *
   * User clicks on a tile displayed in the homepage.
   *
   * Triggers:
   * 1. User clicks on a tile on the homepage. 
   *   _Triggered on unified-home_
   *   ![](https://lh3.googleusercontent.com/99U6eBXE1yC9l8iiaCQhAGBUVWYA22mvQMS8NFMvo3bHzSP2POXKaPwDD9cvVHRD7cflUmDZ81ATic0FlDkdcsAp-7l0dsu5yTcQig=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/bh0e_uDPF2/trigger/qEdxXK3Gr)  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * @param properties The event's properties (e.g. Feed section)
   * @param options Amplitude event options.
   */
  homepageTileClicked(
    properties: HomepageTileClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageTileClicked(properties), options);
  }

  /**
   * Homepage Tiles Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Homepage%20Tiles%20Viewed)
   *
   * User views the tiles on the homepage, displayed outside the feed.
   *
   * Triggers:
   * 1. User views the homepage and is displayed tiles relating to 'Your organization'. 
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/68vYfzmtQiLv119psPMZQaBOz6JWEgd-W50fSXL7YLE3338FL0JPo2dWQ_EWEwpDOH84PArCjWXHeD_JMZoyHk_6BtojKuqSzq00Dw=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/JbS80vORrg/trigger/y_2tVU7ND)  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * @param properties The event's properties (e.g. Number of tiles displayed)
   * @param options Amplitude event options.
   */
  homepageTilesViewed(
    properties: HomepageTilesViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomepageTilesViewed(properties), options);
  }

  /**
   * Onboarding Questionnaire Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Onboarding%20Questionnaire%20Completed)
   *
   * A user completes the onboarding questionnaire.  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * Owner: data@amplitude.com
   *
   * @param properties The event's properties (e.g. Help option)
   * @param options Amplitude event options.
   */
  onboardingQuestionnaireCompleted(
    properties: OnboardingQuestionnaireCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionnaireCompleted(properties), options);
  }

  /**
   * Onboarding Questionnaire Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Onboarding%20Questionnaire%20Started)
   *
   * A user views the onboarding questionnaire.  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * Owner: data@amplitude.com
   *
   * @param options Amplitude event options.
   */
  onboardingQuestionnaireStarted(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionnaireStarted(), options);
  }

  /**
   * Unified Home Task Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Unified%20Home%20Task%20Clicked)
   *
   * A user clicks on one of the tasks to action it  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * Owner: data@amplitude.com
   *
   * @param properties The event's properties (e.g. Action title)
   * @param options Amplitude event options.
   */
  unifiedHomeTaskClicked(
    properties: UnifiedHomeTaskClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnifiedHomeTaskClicked(properties), options);
  }

  /**
   * Unified Home Tile Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Unified%20Home%20Tile%20Clicked)
   *
   * A user clicks on one of the tiles in unified home
   *
   * Triggers:
   * 1. User clicks on tiled in unified home 
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/iYpCBhsm7cCzsb9jGjQ6GSR4JdCxqWC0ug_s265VqPgsvREuWDycPGxy_zy3aucVopOEbD2Df7alONYEcnAd8gHCmt35pF_G9N0=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/AMY92_uy32u1/trigger/1SYis6oUC)
   * 2. Triggered when a user clicks on the Launch 360 button, and is then presented 
   * with a modal window.
   *
   * The Launch 360 button is located on the Drive your development tile, situated on the Unified home page.  
   *   _Triggered on unified-home_
   *   ![](https://lh3.googleusercontent.com/se9cYOKgsXjrLqDQTFXrVC0TYicPRHMtxCpCsZsbL38rB84Ts-uK_LzyfnM7vh-0t3OKUyrtYp7olUH5RM6n7fWsPYOwpoGl-Uyt=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/AMY92_uy32u1/trigger/s8AUAmUhf)
   * 3. Triggered when a user clicks 'Start 360' in the modal window.
   *
   * User reaches modal window after selecting the Launch 360 button, from the Drive your development tile. 
   *   _Triggered on unified-home_
   *   ![](https://lh3.googleusercontent.com/YkElD8m5sH6vnzjdaRDBDefxTH9WfJpMV_zKKlVtJd2wCX1-eTHCCeT95pttQtO36A8f__8__RO0DKINkpOQURPqgRUvGnJkXLky=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/AMY92_uy32u1/trigger/1c19zx_eQ)
   * 4. User clicks on the CTA within the Direct Reports Tile Type 
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/C26EsXqAxooXrvVv7U1BFCiiXqew0FGPwSl56-wXDGR16AmXZSynOnFQBrXXqvAgPVwsqFQ05R7jhXjXLCzr1StYwn3FCp3nTjhR=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/AMY92_uy32u1/trigger/Qc5cr-b3d)
   * 5. "Explore analytics" is clicked 
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/ntDv_PVDc0nZ2Kq4_mMNUx8VBjnimthVn09zgeFbqL0Ssc0g4K04Pmdd7xeKyRC1a3-cb5xH8RgfsmcCrcL9CQu6vVKPOHmK0CPu=w700)  
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/AMY92_uy32u1/trigger/_ZcgbgNFq)  
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * Owner: data@amplitude.com
   *
   * @param properties The event's properties (e.g. Tile name)
   * @param options Amplitude event options.
   */
  unifiedHomeTileClicked(
    properties: UnifiedHomeTileClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnifiedHomeTileClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
